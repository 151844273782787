// const BASE_URL = "https://whale-app-rqcs6.ondigitalocean.app/api";
// const BASE_URL = 'https://lobster-app-5gysu.ondigitalocean.app/api'
const BASE_URL = 'https://solar-sqre66.online/api'
// const BASE_URL = 'http://localhost:8000/api'
export const redirectUrl = 'https://solar-sqre66.online'
// export const redirectUrl = 'http://localhost:3002'

// const BASE_URL = 'https://plankton-app-lw2hd.ondigitalocean.app/api'

export default BASE_URL;

//https://stingray-app-myegm.ondigitalocean.app/api
//http://64.227.128.113/api
//https://sstonebats.com/api/
//https://whale-app-rqcs6.ondigitalocean.app/api
